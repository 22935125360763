import "../App.css";

// REACT + GENERAL LIBRARIES

import React, { useState, useEffect, useRef } from "react";
import dayjs, { Dayjs } from "dayjs";
import * as _ from "lodash";

import {
  eventCourtTimes,
  firestoreAutoId,
  leagueCourtTimes,
} from "../processEmails";

// CONTEXTS

import { createContext, useContext } from "react";

// FIREBASE

import {
  collection,
  getDocs,
  query,
  onSnapshot,
  runTransaction,
  doc,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { db } from "../firebase";

// MUI

import { styled } from "@mui/material/styles";

import {
  AppBar,
  Toolbar,
  IconButton,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  stepContentClasses,
  Box,
  Badge,
  Typography,
  Icon,
  Stack,
  Button,
  Modal,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Grid,
  Fab,
  Menu,
  MenuItem,
  Switch,
  ListItemText,
  ListItemIcon,
  Fade,
} from "@mui/material";
import TrapFocus from "@mui/material/Unstable_TrapFocus";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";

// MUI ICONS

import AddIcon from "@mui/icons-material/Add";
import EmailIcon from "@mui/icons-material/Email";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import MenuIcon from "@mui/icons-material/Menu";
import AssessmentIcon from "@mui/icons-material/Assessment";
import TodayIcon from "@mui/icons-material/Today";
import SortIcon from "@mui/icons-material/Sort";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EditNoteIcon from "@mui/icons-material/EditNote";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";

// DND

import { DndContext, UniqueIdentifier } from "@dnd-kit/core";
import {
  DragEndEvent,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from "@dnd-kit/core";

// TYPES

import { AdminContext, syncEventToOpenSpots, updateEvent } from "../App";
import {
  MatchType,
  EventType,
  PlayerDetailsTypeWithAvailability,
  MatchPlayerType,
  EmailType,
  EventSummary,
  LeagueType,
  MatchSpotsAndPlayersType,
  PlayerDetailsTypeWithAvailabilityAndIndex,
} from "../types";

import { AppContext } from "../App";
import { match } from "assert";
import { flexbox } from "@mui/system";

import { TennisCourt } from "./TennisCourt";
import { eventOpenSpots } from "../processEmails";
import { Availabilities } from "./Availabilities";
import { EventMessages } from "./EventMessages";
import { EventStatistics, EventRoster } from "./EventStatistics";

import {
  PlayerButtonProps,
  PlayerButton,
  PlayerButtonPresentation,
  PlayerPostitPresentation,
} from "./PlayerButton";

import { EventTitle } from "./EventTitle";

// FUNCTIONS

import { setPlayerAvailability } from "../App";
import { Emails } from "./Emails";
import { EventPriority } from "./EventPriority";
import { EventAlert } from "./EventAlert";
import { AddParticipant } from "./AddParticipant";
import { DraftStateType, EventDraftBar } from "./EventDraftBar";
import { EventMatches } from "./EventMatches";

// STYLES

export const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// COMPONENTS

export type hPlayersWithAvailabilitiesType = {
  [PlayerID: string]: PlayerDetailsTypeWithAvailability;
};

export type hPlayersWithAvailabilitiesAndIndexType = {
  [PlayerID: string]: PlayerDetailsTypeWithAvailabilityAndIndex;
};

//
//
//

const StyledFab = styled(Fab)({
  position: "absolute",
  zIndex: 1,
  top: 5,
  left: 0,
  right: 0,
  margin: "0 auto",
});

//
// EVENT ALERT
//

type NonModalType = {
  message: string;
  buttonLabel?: string;
  buttonAction?: () => void;
};

//
// EVENT
//

export const draftFromEvent = (event: EventType) => {
  return _.zipObject(
    Object.keys(event.matches),
    Object.values(event.matches).map((m) => {
      const mps: MatchSpotsAndPlayersType = {
        MatchID: m.MatchID,
        players: _.clone(m.players),
        spots: _.cloneDeep(m.spots),
      };
      return mps;
    })
  );
};

export type EventProps = {
  event: EventType;
  lid: string;
  showDetails: boolean;
};

export const Event = (props: EventProps) => {
  const { state, setState } = useContext(AppContext);
  const { state: adminState, setState: setAdminState } =
    useContext(AdminContext);
  const [currentPlayer, setCurrentPlayer] = useState<string | undefined>(
    state ? state.loggedPlayer?.uid : undefined
  );
  const { event, lid, showDetails } = props;

  // brings up the email dialog
  // const [showEmails, setShowEmails] = useState(false);

  // brings up dialog to set priority policy
  const [showPriorityPolicy, setShowPriorityPolicy] = useState(false);

  // brings up the modal with w-l-d
  const [showStatistics, setShowStatistics] = useState<
    "none" | "results" | "time" | "roster"
  >("none");

  // brings up the modal to add participants
  const [showAddParticipant, setShowAddParticipant] = useState<boolean>(false);

  // shows the nonmodal at bottom that e.g. offers to send emails
  const [nonModal, setNonModal] = useState<NonModalType | undefined>(undefined);

  // for menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // edit mode and current draf
  const [draftState, setDraftState] = useState<DraftStateType>({
    editMode: false,
    currentDraftName: undefined,
    saved: false,
    published: true,
    draft: {},
  });

  const mode =
    state.mode === "email" ? "email" : draftState.editMode ? "draft" : "none";

  // DND

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 8,
    },
  });
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 200,
      tolerance: 8,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  const [playerDragged, setPlayerDragged] = useState<PlayerButtonProps | null>(
    null
  );

  //
  // HANDLE DRAG START AND END
  //

  async function handleDragEnd(dragevent: DragEndEvent) {
    const destination = dragevent.over?.id;

    if (playerDragged && destination && state.currentLeague) {
      //   console.log(JSON.stringify(playerDragged, null, 2));
      //   alert("Came from " + playerDragged.position);
      //   alert("Going to " + destination);
      const dest_mid = (destination as string).split("/")[0];
      const dest_pos = (destination as string).split("/")[1];

      console.log("dest_mid: " + dest_mid);

      // see what's at destination
      const at_destt = Object.values(
        draftState.editMode ? draftState.draft : event.matches
      ).reduce(
        (p, c) =>
          (p as MatchPlayerType[]).concat(
            (c as MatchType).MatchID === dest_mid
              ? (c as MatchType).players.filter((p) => p.position === dest_pos)
              : ([] as MatchPlayerType[])
          ),
        [] as MatchPlayerType[]
      ) as MatchPlayerType[];

      const at_dest: MatchPlayerType | undefined =
        at_destt.length >= 1 ? at_destt[0] : undefined;

      //   alert(JSON.stringify(at_dest, null, 2));

      //   if (at_dest.length == 1) alert(at_dest[0].PlayerID + " at dest");

      //
      // writing to database
      //

      // if we dragged someone from a slot, we need to figure out
      // what happens when we drop
      // do we duplicate the player?
      // do we exchange positions with existing player?
      // do we leave an empty slot?

      var from_todo: "nothing" | "empty" | "swap" = "nothing";

      if (playerDragged.position && at_dest) {
        // alert("Going from player to player");
        from_todo = "swap";
      } else if (!playerDragged.position && at_dest) {
        // alert("Getting scheduled to replace player");
      } else if (playerDragged.position && destination === "availabilities") {
        // alert("Going to availabilities");
        from_todo = "empty";
      } else if (playerDragged.position && !at_dest) {
        // alert("Moving to empty slot");
        if (window.confirm("Move (OK) or duplicate (Cancel)?")) {
          from_todo = "empty";
        }
      } else if (!playerDragged.position && at_dest) {
        // alert("Getting scheduled to empty slot");
      }

      console.log(from_todo);

      // getting matches

      const newmatches = draftState.editMode
        ? _.cloneDeep(draftState.draft)
        : _.cloneDeep(event.matches);

      // dealing with source when dragging a player
      if (
        playerDragged.mid &&
        playerDragged &&
        playerDragged.player !== undefined
      ) {
        if (from_todo === "empty") {
          // remove player from the origin spot
          newmatches[playerDragged.mid].players = newmatches[
            playerDragged.mid
          ].players.filter(
            (p) =>
              // p.PlayerID !== playerDragged.player?.PlayerID &&
              p.position !== playerDragged.position
          );
        } else if (from_todo === "swap" && at_dest) {
          // putting the destination guy in origin
          newmatches[playerDragged.mid].players = newmatches[
            playerDragged.mid
          ].players.map((p) =>
            p.PlayerID === playerDragged.player?.PlayerID &&
            p.position === playerDragged.position
              ? { ...p, PlayerID: at_dest.PlayerID }
              : p
          );
        }
      }

      // dealing with source when dragging a postit
      if (playerDragged.mid && playerDragged && playerDragged.postitname) {
        if (from_todo === "empty") {
          // remove player from the origin spot
          newmatches[playerDragged.mid].players = newmatches[
            playerDragged.mid
          ].players.filter((p) => p.position !== playerDragged.position);
        } else if (from_todo === "swap" && at_dest) {
          // putting the destination guy in origin
          newmatches[playerDragged.mid].players = newmatches[
            playerDragged.mid
          ].players.map((p) =>
            p.postitname === playerDragged.postitname &&
            p.position === playerDragged.position
              ? { ...p, PlayerID: at_dest.PlayerID }
              : p
          );
        }
      }

      // dealing with destination
      if (destination !== "availabilities") {
        if (at_dest) {
          console.log("dropping on at_dest");
          console.log(JSON.stringify(at_dest, null, 2));

          newmatches[dest_mid].players = newmatches[dest_mid].players.map(
            (p) => {
              if (p.position === dest_pos) {
                if (playerDragged.player) {
                  return {
                    PlayerID: playerDragged.player.PlayerID,
                    position: p.position,
                    side: p.side,
                  };
                } else {
                  console.log("Replacing with " + playerDragged.postitname);
                  return {
                    postitname: playerDragged.postitname || "???",
                    position: p.position,
                    side: p.side,
                  };
                }
              } else return p;
            }
          );
        } else {
          // if no spot we have to create one
          // checking what spot is there at destination
          if (newmatches[dest_mid].spots[dest_pos]) {
            // there was a spot, do nothing
          } else {
            newmatches[dest_mid].spots[dest_pos] = {
              position: dest_pos,
              MatchID: dest_mid,
            };
          }

          if (playerDragged.player) {
            newmatches[dest_mid].players.push({
              PlayerID: playerDragged.player.PlayerID,
              position: dest_pos,
              side: dest_pos.slice(0, 1),
            });
          } else {
            newmatches[dest_mid].players.push({
              postitname: playerDragged.postitname || "???",
              position: dest_pos,
              side: dest_pos.slice(0, 1),
            });
          }
        }
      }

      // computing open spots
      // and before and after court times
      //
      // we duplicate the event, apply the changes locally, then compute
      // the open spots, then actually write to db both the changes
      // and the open spots - phew!
      //

      // if editing a draft we don't need to tweak availabilities and confirmation
      // and we don't need to update openspots

      const newevent = _.cloneDeep(event);
      Object.keys(newmatches).forEach((mid) => {
        // patching the event with new matches
        newevent.matches[mid] = _.assign(
          newevent.matches[mid],
          newmatches[mid]
        );
      });
      if (destination === "availabilities" && playerDragged.player?.confirmed) {
        newevent.availabilities[playerDragged.player.PlayerID || ""].confirmed =
          false;
      }
      const openspots = eventOpenSpots(newevent);

      // updateLeague
      // updateLeagueWithEventSummary(
      //   state.leagues[lid],
      //   playerDragged.event,
      //   newevent
      // );

      // if moving out of court, we remove confirmed flag
      if (destination === "availabilities" && playerDragged.player?.confirmed) {
        if (draftState.editMode) {
          setDraftState({ ...draftState, draft: newmatches, saved: false });
        } else {
          updateEvent(state.leagues[lid], event, {
            matches: newmatches,
            ["availabilities." + playerDragged.player.PlayerID + ".confirmed"]:
              false,
            nbopenspots: openspots.length,
            openspots: openspots,
          });
        }
      } else {
        if (draftState.editMode) {
          const newDraftState = {
            ...draftState,
            draft: newmatches,
            saved: false,
          };
          // console.log(JSON.stringify(newDraftState, null, 2));
          setDraftState(newDraftState);
        } else {
          updateEvent(state.leagues[lid], event, {
            matches: newmatches,
            nbopenspots: openspots.length,
            openspots: openspots,
          });
        }
      }

      // we save the league if there was a difference

      // we display the tray if we scheduled a player
      if (
        !draftState.editMode &&
        !playerDragged.position &&
        destination !== "availabilities" &&
        playerDragged.player
      ) {
        const templates = Object.values(adminState.templates).filter(
          (v) => v.name === "Ask for confirmation"
        );
        const template = templates.length > 0 ? templates[0] : undefined;
        if (template) {
          setNonModal({
            message:
              "You scheduled " +
              playerDragged.player.name +
              ". Do you want to send an email to ask for confirmation?",
            buttonLabel: "send",
            buttonAction: async () => {
              // const templates = Object.values(adminState.templates).filter(
              //   (v) => v.name === "Ask for confirmation"
              // );
              // const template = templates.length > 0 ? templates[0] : undefined;
              if (template) {
                const newemail: EmailType = {
                  EmailID: firestoreAutoId(),
                  author: state.loggedPlayer?.uid || "",
                  sent: false,
                  toGroups: [],
                  lessGroups: [],
                  toIndividuals: [playerDragged.player?.PlayerID || ""],
                  lessIndividuals: [],
                  TemplateID: template.EmailTemplateID,
                  whenCategory: "immediately",
                  whenValue: 0,
                  sendAt: dayjs().add(5, "minute").toISOString(),
                  triggered: false,
                  triggeredAt: "",
                  options: _.cloneDeep(template.options),
                  LeagueID: lid,
                  EventID: event.PlayingDayID,
                  test: false,
                  individualized: true,
                };
                // addEmail
                const emailRef = doc(
                  db,
                  "leagues",
                  lid,
                  "events",
                  event.PlayingDayID,
                  "emails",
                  newemail.EmailID
                );
                setDoc(emailRef, newemail);
                setNonModal({ message: "Scheduled email in 5 minutes" });
              } else {
                setNonModal({ message: "No template" });
              }
            },
          });
        }
      } else if (
        !draftState.editMode &&
        playerDragged.position &&
        destination == "availabilities" &&
        playerDragged.player
      ) {
        const templates = Object.values(adminState.templates).filter(
          (v) => v.name === "Notify descheduled"
        );
        const template = templates.length > 0 ? templates[0] : undefined;
        if (template) {
          setNonModal({
            message:
              "You descheduled " +
              playerDragged.player.name +
              ". Do you want to send an email to notify?",
            buttonLabel: "send",
            buttonAction: async () => {
              // const templates = Object.values(adminState.templates).filter(
              //   (v) => v.name === "Ask for confirmation"
              // );
              // const template = templates.length > 0 ? templates[0] : undefined;
              if (template) {
                const newemail: EmailType = {
                  EmailID: firestoreAutoId(),
                  author: state.loggedPlayer?.uid || "",
                  sent: false,
                  toGroups: [],
                  lessGroups: [],
                  toIndividuals: [playerDragged.player?.PlayerID || ""],
                  lessIndividuals: [],
                  TemplateID: template.EmailTemplateID,
                  whenCategory: "immediately",
                  whenValue: 0,
                  sendAt: dayjs().add(5, "minute").toISOString(),
                  triggered: false,
                  triggeredAt: "",
                  options: _.cloneDeep(template.options),
                  LeagueID: lid,
                  EventID: event.PlayingDayID,
                  test: false,
                  individualized: true,
                };
                // addEmail
                const emailRef = doc(
                  db,
                  "leagues",
                  lid,
                  "events",
                  event.PlayingDayID,
                  "emails",
                  newemail.EmailID
                );
                setDoc(emailRef, newemail);
                setNonModal({ message: "Scheduled email in 5 minutes" });
              } else {
                setNonModal({ message: "No template" });
              }
            },
          });
        }
      }
    }
    setPlayerDragged(null);
  }

  function handleDragStart(event: DragStartEvent) {
    setPlayerDragged(event.active.data.current as unknown as PlayerButtonProps);
  }

  const addCourt = async () => {
    const name = prompt("New court name");
    if (name) {
      // adding
      const newmatches: { [MatchID: string]: MatchType } = JSON.parse(
        JSON.stringify(event.matches)
      );

      const newMatch: MatchType = {
        MatchID: firestoreAutoId(),
        court: name,
        order: Object.keys(newmatches).length + 1,
        awins: "no",
        bwins: "no",
        score: "",
        players: [],
        spots: {},
        minutes: state.leagues[lid].minutes || 90,
      };

      newmatches[newMatch.MatchID] = newMatch;

      // const eventRef = doc(db, "leagues", lid, "events", event.PlayingDayID);
      updateEvent(state.leagues[lid], event, { matches: newmatches });
      // await updateDoc(eventRef, "matches", newmatches);
    }
  };

  const changePostIt = (
    mid?: string,
    position?: string,
    postitname?: string
  ) => {
    if (mid && position && postitname) {
      const newname = window.prompt("New name", postitname);
      if (newname) {
        const newPlayers = event.matches[mid].players.map((p) => {
          if (p.position === position) {
            return { ...p, postitname: newname };
          } else return p;
        });
        updateEvent(state.leagues[lid], event, {
          ["matches." + mid + ".players"]: newPlayers,
        });
      }
    }
  };
  // SCROLL TO TOP ON FIRST RENDER

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //
  // NEXT AND PREVIOUS
  //

  type EventSummaryWithID = EventSummary & {
    PlayingDayID: string;
    LeagueID: string;
  };

  const getEvents = () => {
    // const allEvents = _.flattenDeep(
    //   Object.values(state.events).map((h) => Object.values(h))
    // )
    //   .filter(
    //     (e) =>
    //       !state.hiddenLeagues.includes(e.LeagueID) &&
    //       (state.navigateWithinLeague === false ||
    //         e.LeagueID === state.currentLeague)
    //   )
    //   .sort((a, b) => (a.when > b.when ? 1 : -1));

    // return allEvents;

    const allEvents: EventSummaryWithID[] = _.flatten(
      Object.values(state.leagues)
        .filter(
          (l) =>
            !state.hiddenLeagues.includes(l.LeagueID) &&
            (state.navigateWithinLeague === false ||
              l.LeagueID === state.currentLeague)
        )
        .map((l) =>
          Object.entries(l.eventsSummary || {}).map(([k, v]) => {
            return { ...v, LeagueID: l.LeagueID, PlayingDayID: k };
          })
        )
    ).sort((a, b) => (a.when > b.when ? 1 : -1));

    return allEvents;
  };

  const constNextPrevious = (next: boolean) => {
    const allEvents = getEvents();
    const index = allEvents.findIndex(
      (e) => e.PlayingDayID === state.currentEvent
    );
    const newindex = index + (next ? 1 : -1);
    if (newindex >= 0 && newindex < allEvents.length) {
      setState((state) => {
        return {
          ...state,
          currentEvent: allEvents[newindex].PlayingDayID,
          currentLeague: allEvents[newindex].LeagueID,
          currentDay: dayjs(allEvents[newindex].when)
            .startOf("day")
            .format("YYYY-MM-DD"),
        };
      });
    }
  };

  const today = () => {
    const allEvents = getEvents();
    const index = allEvents.findIndex(
      (e) => dayjs(e.when) >= dayjs().startOf("day")
    );
    if (index >= 0 && index < allEvents.length) {
      setState((state) => {
        return {
          ...state,
          currentEvent: allEvents[index].PlayingDayID,
          currentLeague: allEvents[index].LeagueID,
          currentDay: dayjs(allEvents[index].when)
            .startOf("day")
            .format("YYYY-MM-DD"),
        };
      });
    }
    handleMenuClose();
  };
  // RENDER

  //
  // Court times
  //

  const courtTimes = showDetails ? leagueCourtTimes(state.leagues[lid]) : {};

  // console.log(courtTimes);

  if (state && state.leagues[lid]) {
    const league = state.leagues[lid];

    const players: hPlayersWithAvailabilitiesType = JSON.parse(
      JSON.stringify(league.playerDetails)
    );

    // console.log(JSON.stringify(players));

    //
    // if draft mode, we pull players from draft, not from match
    //

    const playing = _.uniq(
      _.flatten(
        Object.values(
          draftState.editMode ? draftState.draft : event.matches
        ).map((m) => m.players.map((p) => p.PlayerID))
      )
    );

    //
    // now fetching availabilities
    //

    Object.keys(players).forEach((pid) => {
      players[pid] = {
        ...players[pid],
        PlayerID: pid,
      };

      if (event.availabilities && event.availabilities[pid]) {
        players[pid] = {
          ...players[pid],
          availability: event.availabilities[pid].availability,
          confirmed: event.availabilities[pid].confirmed,
        };
      }
      if (playing.includes(pid)) {
        players[pid] = { ...players[pid], playing: true };
      }
    });

    // const playersWithAvailabilities = Object.values(players).sort((a, b) =>
    //   a.name > b.name ? 1 : -1
    // );

    // const player = currentPlayer
    //   ? {
    //       ...state.leagues[lid].playerDetails[currentPlayer],
    //       ...event.availabilities[currentPlayer],
    //     }
    //   : null;

    const editor =
      (state.loggedPlayer && league.admins.includes(state.loggedPlayer.uid)) ||
      state.tokenResult?.claims.license === "sysadmin";

    return (
      <DndContext
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        sensors={sensors}
      >
        <DragOverlay dropAnimation={null}>
          {/* 
            
            This is the floating element that is displayed when you
            are dragging a player across the screen
            
            */}
          {playerDragged && playerDragged.player ? (
            <PlayerButtonPresentation
              key={playerDragged.player.PlayerID}
              player={playerDragged.player}
              // matchesPlayersAndSpots={event.matches}
              forAvailability={false}
              currentPlayer={currentPlayer}
              setCurrentPlayer={setCurrentPlayer}
              // lid={lid}
            />
          ) : playerDragged && playerDragged.postitname ? (
            <PlayerPostitPresentation
              key="postit"
              postitname={playerDragged.postitname}
            />
          ) : (
            <></>
          )}
        </DragOverlay>

        {/* Non modal */}
        {showDetails ? (
          <TrapFocus open disableAutoFocus disableEnforceFocus>
            <Fade appear={false} in={nonModal !== undefined}>
              <Paper
                role="dialog"
                aria-modal="false"
                aria-label="Notify player?"
                square
                variant="outlined"
                tabIndex={-1}
                sx={{
                  position: "fixed",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  m: 0,
                  p: 2,
                  borderWidth: 0,
                  borderTopWidth: 1,
                  zIndex: 100,
                }}
              >
                <Stack direction="column" spacing={1}>
                  <Typography>{nonModal?.message}</Typography>
                  <Stack
                    direction="row"
                    sx={{ justifyContent: "space-around" }}
                  >
                    {nonModal?.buttonLabel ? (
                      <Button
                        variant="contained"
                        onClick={() => {
                          if (nonModal?.buttonAction) nonModal.buttonAction();
                        }}
                      >
                        {nonModal?.buttonLabel}
                      </Button>
                    ) : (
                      <></>
                    )}
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        setNonModal(undefined);
                      }}
                    >
                      Dismiss
                    </Button>
                  </Stack>
                </Stack>
              </Paper>
            </Fade>
          </TrapFocus>
        ) : (
          <></>
        )}

        {/* APP BAR */}
        {showDetails ? (
          <AppBar
            position="fixed"
            sx={{
              minHeight: 70,
              top: "auto",
              bottom: 70,
              bgcolor: "#ddd",
              padding: 1,
              zIndex: 50,
            }}
            elevation={0}
          >
            <Stack
              direction="row"
              sx={{
                marginTop: 0,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                size="large"
                startIcon={<KeyboardArrowLeftIcon />}
                sx={{ height: 50, width: 150 }}
                variant="contained"
                onClick={() => {
                  constNextPrevious(false);
                }}
              >
                Previous
              </Button>

              <StyledFab
                color="secondary"
                aria-label="add"
                onClick={handleMenuClick}
              >
                {mode === "none" ? (
                  <MenuIcon />
                ) : mode === "draft" ? (
                  <EditNoteIcon />
                ) : (
                  <EmailIcon />
                )}
              </StyledFab>

              {showPriorityPolicy ? (
                <EventPriority
                  showModal={showPriorityPolicy}
                  setShowModal={setShowPriorityPolicy}
                  event={event}
                  lid={lid}
                />
              ) : (
                <></>
              )}

              {showStatistics == "roster" ? (
                <EventRoster
                  closeModal={() => {
                    setShowStatistics("none");
                  }}
                  event={event}
                  league={league}
                ></EventRoster>
              ) : showStatistics != "none" ? (
                <EventStatistics
                  showModal={showStatistics}
                  closeModal={() => {
                    setShowStatistics("none");
                  }}
                  event={event}
                  league={league}
                  courtTimes={courtTimes}
                />
              ) : (
                <></>
              )}

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
              >
                {league.admins.includes(state.loggedPlayer?.uid || "") ||
                state.tokenResult?.claims.license === "sysadmin" ? (
                  <>
                    <MenuItem
                      onClick={() => {
                        handleMenuClose();
                        setState((state) => {
                          return { ...state, mode: "email" };
                        });
                      }}
                    >
                      <ListItemIcon>
                        <EmailIcon />
                      </ListItemIcon>
                      <ListItemText>Mailing center</ListItemText>
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={() => {
                        handleMenuClose();
                        setDraftState({
                          editMode: true,
                          currentDraftName: undefined,
                          draft: draftFromEvent(event),
                          saved: true,
                          published: false,
                        });
                      }}
                    >
                      <ListItemIcon>
                        <EditNoteIcon />
                      </ListItemIcon>
                      <ListItemText>Draft mode</ListItemText>
                    </MenuItem>
                  </>
                ) : (
                  <></>
                )}

                <Divider />
                <MenuItem
                  onClick={() => {
                    handleMenuClose();
                    setShowPriorityPolicy(true);
                  }}
                >
                  <ListItemIcon>
                    <SortIcon />
                  </ListItemIcon>
                  <ListItemText>Player priority policy</ListItemText>
                </MenuItem>
                <MenuItem>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={state.showPlayerPriority}
                        onChange={() => {
                          setState((state) => {
                            return {
                              ...state,
                              showPlayerPriority: !state.showPlayerPriority,
                            };
                          });
                        }}
                      />
                    }
                    label="Show player priority"
                  />
                </MenuItem>

                <Divider />

                <MenuItem
                  onClick={() => {
                    handleMenuClose();
                    setShowStatistics("roster");
                  }}
                >
                  <ListItemIcon>
                    <PeopleAltIcon />
                  </ListItemIcon>
                  <ListItemText>Roster</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleMenuClose();
                    setShowStatistics("time");
                  }}
                >
                  <ListItemIcon>
                    <AccessTimeIcon />
                  </ListItemIcon>
                  <ListItemText>Court time</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleMenuClose();
                    setShowStatistics("results");
                  }}
                >
                  <ListItemIcon>
                    <AssessmentIcon />
                  </ListItemIcon>
                  <ListItemText>Results</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={state.navigateWithinLeague}
                        onChange={() => {
                          setState((state) => {
                            return {
                              ...state,
                              navigateWithinLeague: !state.navigateWithinLeague,
                            };
                          });
                        }}
                      />
                    }
                    label="Navigate within league"
                  />
                </MenuItem>
                <MenuItem onClick={today}>
                  <ListItemIcon>
                    <TodayIcon />
                  </ListItemIcon>
                  <ListItemText>Today</ListItemText>
                </MenuItem>
              </Menu>

              <Button
                size="large"
                sx={{ height: 50, width: 150 }}
                variant="contained"
                endIcon={<KeyboardArrowRightIcon />}
                onClick={() => {
                  constNextPrevious(true);
                }}
              >
                Next
              </Button>
            </Stack>
          </AppBar>
        ) : (
          <></>
        )}

        <Paper
          elevation={showDetails ? 0 : 3}
          sx={{
            marginLeft: showDetails ? 2 : 0,
            marginRight: showDetails ? 2 : 0,
            marginTop: showDetails ? 2 : 0,
          }}
        >
          {/* 
          
          Title
          
          */}

          {/* no title if in draft mode */}
          {!(mode === "draft") ? (
            <>
              <Box
                sx={{
                  marginTop: 2,
                  marginLeft: 2,
                  marginRight: 2,
                  padding: 1,
                  justifyContent: "center",
                }}
              >
                <EventTitle
                  {...props}
                  player={currentPlayer ? players[currentPlayer] : undefined}
                  hideAvailAndSpots={mode === "email"}
                />

                {mode === "none" ? (
                  <EventMessages
                    event={event}
                    lid={lid}
                    showDetails={showDetails}
                  />
                ) : (
                  <></>
                )}
              </Box>

              {mode === "none" ? <EventAlert event={event} /> : <></>}
            </>
          ) : (
            <></>
          )}

          {(() => {
            if (mode === "email") {
              return (
                <Emails
                  showModal={mode === "email"}
                  setShowModal={() => {
                    setState((state) => {
                      return { ...state, mode: "none" };
                    });
                  }}
                  event={event}
                  lid={lid}
                />
              );
            } else if (mode === "draft") {
              /* Draft bar when in draft mode */
              return (
                <EventDraftBar
                  league={league}
                  event={event}
                  draftState={draftState}
                  setDraftState={setDraftState}
                  setEditMode={(a: boolean) => {
                    setDraftState({ ...draftState, editMode: a });
                  }}
                />
              );
            } else {
              /* Button to switch to draft mode */
              // if (editor && showDetails && !draftState.editMode) {
              //   return (
              //     <Button
              //       onClick={() => {
              //         if (draftState.editMode === false) {
              //           setDraftState({
              //             editMode: true,
              //             currentDraftName: undefined,
              //             draft: draftFromEvent(event),
              //             saved: true,
              //             published: false,
              //           });
              //         }
              //       }}
              //     >
              //       {"Edit in draft mode"}
              //     </Button>
              //   );
              // }
            }
          })()}

          {/* 
          Matches and availabilities          
          */}

          {(showDetails && mode === "none") || mode === "draft" ? (
            <Stack direction="column">
              <EventMatches
                lid={lid}
                event={event}
                editor={editor}
                playerDragged={playerDragged}
                currentPlayer={currentPlayer}
                setCurrentPlayer={setCurrentPlayer}
                draftState={draftState}
                setDraftState={setDraftState}
                showDetails={showDetails}
                players={players}
                addCourt={addCourt}
                changePostIt={changePostIt}
                courtTimes={courtTimes}
              />

              <Divider sx={{ marginTop: 2, marginBottom: 2 }} />

              <Availabilities
                key={event.PlayingDayID}
                eid={event.PlayingDayID}
                lid={lid}
                showDetails={false}
                playersWithAvailabilities={players}
                playerDragged={playerDragged ? playerDragged : undefined}
                currentPlayer={currentPlayer}
                setCurrentPlayer={setCurrentPlayer}
                courtTimes={courtTimes}
              />

              {/* Add participant plus button */}
              {editor && !draftState.editMode ? (
                <Box>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setShowAddParticipant(true);
                    }}
                  >
                    <AddIcon />
                  </Button>
                </Box>
              ) : (
                <></>
              )}

              {showAddParticipant ? (
                <AddParticipant
                  lid={lid}
                  event={event}
                  showModal={showAddParticipant}
                  setShowModal={setShowAddParticipant}
                />
              ) : (
                <></>
              )}
            </Stack>
          ) : (
            <></>
          )}

          {/* Admins list */}
          {mode === "none" && showDetails ? (
            <Stack direction="column" sx={{ marginTop: showDetails ? 3 : 0 }}>
              <Divider />
              <Typography sx={{ fontSize: 12 }}>
                {state.leagues[lid].standalone ? "event" : "league"} managed by{" "}
                {state.leagues[lid].admins
                  .map(
                    (a) =>
                      state.leagues[lid].playerDetails[a]?.fullname || "???"
                  )
                  .join(", ")}
              </Typography>
              <Divider />
            </Stack>
          ) : (
            <></>
          )}
        </Paper>
      </DndContext>
    );
  } else return <></>;
};
