import "../App.css";

// REACT + GENERAL LIBRARIES

import React, { useState } from "react";
import * as _ from "lodash";

// DND

import { useDroppable, useDraggable } from "@dnd-kit/core";

// CONTEXTS

import { useContext } from "react";

// FIREBASE

// MUI

import { Box, Typography, Stack } from "@mui/material";

// MUI ICONS

import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import VerifiedIcon from "@mui/icons-material/Verified";

// TYPES

import {
  MatchType,
  PlayerDetailsTypeWithAvailabilityAndIndex,
  hMatchSpotsAndPlayersType,
} from "../types";
import { SpotType } from "../types";
import { AppContext, updateEvent, updateLeague } from "../App";
import { EventType } from "../types";
import { PlayerDetailsTypeWithAvailability } from "../types";
import { eventOpenSpots } from "../processEmails";
// import { updateLeagueWithEventSummary } from "./App";

import { LeaguePlayerForm } from "./LeaguePlayerForm";
import { DraftStateType } from "./EventDraftBar";
// COMPONENTS

const playerButtonW = 90;
const playerButtonH = 36;

//
// SLOT
//

type SlotProps = {
  event: EventType;
  match: MatchType;
  position: string;
  player?: PlayerDetailsTypeWithAvailabilityAndIndex;
  postitname?: string;
  playerDragged?: PlayerButtonProps;
  currentPlayer?: string;
  setCurrentPlayer: (cp: string | undefined) => void;
  lid: string;
  draftState: DraftStateType;
  setDraftState: (nds: DraftStateType) => void;
  changePostIt: (mid?: string, position?: string, postitname?: string) => void;
};

export const Slot = (props: SlotProps) => {
  const { state, setState } = useContext(AppContext);
  const {
    player,
    postitname,
    position,
    event,
    playerDragged,
    match,
    setCurrentPlayer,
    currentPlayer,
    lid,
    draftState,
    setDraftState,
    changePostIt,
  } = props;

  // DND
  const { isOver, setNodeRef } = useDroppable({
    id: props.match.MatchID + "/" + position,
  });
  const style = {
    opacity: playerDragged ? (isOver ? 1 : 0.5) : 1,
  };

  // CREATE SLOT
  const createSlot = async () => {
    if (
      state.loggedPlayer &&
      (state.leagues[lid].admins.includes(state.loggedPlayer.uid) ||
        state.tokenResult?.claims.license === "sysadmin")
    ) {
      // adding a slot
      const newmatch = _.cloneDeep(
        draftState.editMode ? draftState.draft[match.MatchID] : match
      );

      if (newmatch.spots === undefined) {
        const newspots = {} as { [position: string]: SpotType };
        newspots[position] = { position: position, MatchID: newmatch.MatchID };
        newmatch.spots = newspots;
      } else if (newmatch.spots && newmatch.spots[position]) {
        delete newmatch.spots[position];
      } else if (newmatch.spots) {
        const existingspots = newmatch.spots;
        existingspots[position] = {
          position: position,
          MatchID: newmatch.MatchID,
        };
      }

      if (draftState.editMode) {
        setDraftState({
          ...draftState,
          draft: { ...draftState.draft, [match.MatchID]: newmatch },
        });
      } else {
        // computing openspots
        // we do update in memory, compute open spots, do actual db write
        const newevent = _.cloneDeep(event);
        _.assign(newevent.matches[match.MatchID], newmatch);
        const openspots = eventOpenSpots(newevent);

        // updating league if needed
        console.log("Created slot");
        // updateLeagueWithEventSummary(state.leagues[lid], event, newevent);

        const update = {
          ["matches." + match.MatchID]: newmatch,
          nbopenspots: openspots.length,
          openspots: openspots,
        };
        updateEvent(state.leagues[lid], event, update);
      }
    }
  };

  const matchtodisplay = draftState.editMode
    ? draftState.draft[match.MatchID]
    : match;

  // RENDER

  if (state) {
    return (
      <div ref={setNodeRef} style={style}>
        <Box
          sx={{
            // minWidth: 92,
            // minHeight: 42,
            border: 0,
            margin: 0,
            padding: 0,
          }}
        >
          {(() => {
            if (player) {
              return (
                <PlayerButton
                  // event={event}
                  // matchesPlayersAndSpots={
                  //   draftState.editMode ? draftState.draft : event.matches
                  // }
                  player={player}
                  position={position}
                  mid={match.MatchID}
                  playerDragged={playerDragged}
                  forAvailability={false}
                  currentPlayer={currentPlayer}
                  setCurrentPlayer={setCurrentPlayer}
                  // lid={lid}
                />
              );
            } else if (postitname) {
              return (
                <PlayerPostit
                  mid={match.MatchID}
                  position={position}
                  postitname={postitname || "???"}
                  onClick={changePostIt}
                />
              );
            } else
              return (
                <Box
                  sx={{
                    minWidth: playerButtonW,
                    minHeight: playerButtonH,

                    backgroundColor: isOver
                      ? "#ccc"
                      : matchtodisplay.spots && matchtodisplay.spots[position]
                      ? "#ddd"
                      : "#eee",
                    border: 1,
                    borderColor: "white",
                    // borderStyle: "dashed",
                    padding: 0,
                  }}
                  onClick={() => {
                    createSlot();
                  }}
                >
                  {matchtodisplay.spots && matchtodisplay.spots[position] ? (
                    <PersonOutlineOutlinedIcon sx={{ marginTop: 0.5 }} />
                  ) : (
                    <>&nbsp;</>
                  )}
                </Box>
              );
          })()}
        </Box>
      </div>
    );
  } else return <></>;
};

//
// PLAYER BUTTON PRESENTATION
//

export type PlayerButtonProps = {
  // event: EventType;
  // matchesPlayersAndSpots: hMatchSpotsAndPlayersType;
  player?: PlayerDetailsTypeWithAvailabilityAndIndex;
  postitname?: string;
  position?: string;
  mid?: string;
  // lid: string;
  // index?: number;
};

export const PlayerButtonPresentation = (
  props: PlayerButtonProps & { playerDragged?: PlayerButtonProps } & {
    forAvailability: boolean;
  } & { currentPlayer: string | undefined } & {
    setCurrentPlayer: (np: string | undefined) => void;
  }
) => {
  const {
    player,
    playerDragged,
    forAvailability,
    currentPlayer,
    setCurrentPlayer,
    // lid,
    // index,
  } = props;

  const { state, setState } = useContext(AppContext);
  const lid = state.currentLeague || "";

  const [editing, setEditing] = useState(false);

  if (player) {
    const bgcol =
      // white if being dragged
      playerDragged && playerDragged.player?.PlayerID === player.PlayerID
        ? "#fff"
        : player.availability &&
          player.availability !== "none" &&
          player.availability !== "unknown" &&
          player.availability !== "out"
        ? player.confirmed
          ? "#AACCFF" // "#AFD793"
          : "#AACCFF"
        : player.availability && player.availability === "none"
        ? "#FC9487"
        : player.availability && player.availability === "out"
        ? "#FEBE15"
        : "#eee";

    const col =
      // white if being dragged
      playerDragged && playerDragged.player?.PlayerID === player.PlayerID
        ? "#fff"
        : "#000";

    if (editing) {
      return (
        <LeaguePlayerForm
          key={"LPF-" + player.PlayerID}
          showModal={editing}
          setShowModal={(a: boolean) => {
            setEditing(a);
          }}
          player={player}
          // lid={lid}
          save={(newPlayerDetails, isAdmin) => {
            if (
              newPlayerDetails.PlayerID !== state.loggedPlayer?.uid ||
              isAdmin ||
              window.confirm("Sure you no longer want to admin this?")
            ) {
              var newAdminList = state.leagues[lid].admins.filter(
                (pid) => isAdmin || pid !== newPlayerDetails.PlayerID
              );
              if (
                isAdmin &&
                !newAdminList.includes(newPlayerDetails.PlayerID)
              ) {
                newAdminList = _.union(newAdminList, [
                  newPlayerDetails.PlayerID,
                ]);
              }
              updateLeague(state.leagues[lid], {
                ["playerDetails." + player.PlayerID]: newPlayerDetails,
                admins: newAdminList,
              });
              setEditing(false);
            }
          }}
        />
      );
    } else if (state) {
      return (
        <Box
          key={"PBP-" + player.PlayerID}
          sx={{
            minWidth: forAvailability ? 0 : playerButtonW - 2,
            minHeight: playerButtonH - 2,

            //   margin: player.PlayerID == state.currentPlayer ? "2px" : "4px",
            //   padding: 0,
            //   paddingTop: 0.7,
            //   border: player.PlayerID == state.currentPlayer ? 2 : 0,
            border: 2,
            borderColor:
              currentPlayer &&
              player.PlayerID &&
              player.PlayerID === currentPlayer
                ? "#000"
                : "#fff",
            //   borderStyle: "dashed",
            backgroundColor: bgcol,
            color: col,
            borderRadius: 2,
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
          onClick={(event) => {
            // you can select other users only if you are admin of this league
            if (
              state.loggedPlayer &&
              (state.leagues[lid].admins.includes(state.loggedPlayer.uid) ||
                state.tokenResult?.claims.license === "sysadmin")
            ) {
              if (
                currentPlayer === player.PlayerID &&
                player.availability !== "out"
              ) {
                event.stopPropagation();
                setEditing(true);
              } else {
                setCurrentPlayer(player.PlayerID);
              }
            }
          }}
        >
          {player.index >= 0 && state.showPlayerPriority ? (
            <Stack direction="row" sx={{ justifyContent: "center" }}>
              <Typography
                sx={{ fontSize: 10, marginTop: 1, marginLeft: "3px" }}
              >
                {player.index}
              </Typography>
              <Typography
                sx={{
                  marginTop: 0.6,
                  marginLeft: player.index ? 0.7 : 1,
                  marginRight: 1,
                  fontStyle: player.external ? "italic" : "normal",
                }}
              >
                {bgcol === "#FC9487" ? (
                  <s>{player.name}</s>
                ) : (
                  <>{player.name}</>
                )}
              </Typography>
            </Stack>
          ) : (
            <Stack direction="row" sx={{ justifyContent: "center" }}>
              {player.confirmed ? (
                <VerifiedIcon
                  // fontSize="large"
                  sx={{ marginLeft: 0, paddingTop: 0.5, color: "#8FAAD5" }}
                />
              ) : (
                <></>
              )}
              <Typography
                sx={{
                  marginTop: 0.6,
                  marginLeft: player.index ? 0.7 : player.confirmed ? 0 : 1,
                  marginRight: 1,
                  fontStyle: player.external ? "italic" : "normal",
                }}
              >
                {bgcol === "#FC9487" ? (
                  <s>{player.name}</s>
                ) : (
                  <>{player.name}</>
                )}
              </Typography>
            </Stack>
          )}
        </Box>
      );
    } else return <></>;
  } else return <></>;
};

interface DraggableProps {
  id: string;
  data: PlayerButtonProps;
  children: JSX.Element[] | JSX.Element;
  playerDragged?: PlayerButtonProps;
}

function Draggable(props: DraggableProps) {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: props.id,
    data: { ...props.data },
  });

  return (
    <div ref={setNodeRef} {...listeners} {...attributes}>
      {props.children}
    </div>
  );
}

type PlayerPostItProps = {
  mid?: string;
  position?: string;
  postitname: string;
  onClick?: (mid?: string, position?: string, postitname?: string) => void;
};

export const PlayerPostitPresentation = (props: PlayerPostItProps) => {
  const { mid, position, postitname, onClick } = props;
  return (
    <Box
      sx={{
        bgcolor: "yellow",
        border: 2,
        padding: 0,
        borderRadius: 1,

        minWidth: playerButtonW - 2,
        minHeight: playerButtonH - 2,

        //   margin: player.PlayerID == state.currentPlayer ? "2px" : "4px",
        //   padding: 0,
        //   paddingTop: 0.7,
        //   border: player.PlayerID == state.currentPlayer ? 2 : 0,

        borderColor: "#ccc",
        borderStyle: "dashed",
        backgroundColor: "white",
        overflow: "hidden",
        whiteSpace: "nowrap",
      }}
      onClick={() => {
        if (onClick) onClick(mid, position, postitname);
      }}
    >
      <Stack direction="row" sx={{ justifyContent: "center" }}>
        <Typography
          sx={{
            marginTop: 0.6,
            marginLeft: 1,
            marginRight: 1,
            fontStyle: "normal",
          }}
        >
          {postitname}
        </Typography>
      </Stack>
    </Box>
  );
};

export const PlayerPostit = (props: PlayerPostItProps) => {
  const { mid, position, postitname, onClick } = props;
  const { state, setState } = useContext(AppContext);

  if (
    state &&
    state.loggedPlayer &&
    ((state.currentLeague &&
      state.leagues[state.currentLeague].admins.includes(
        state.loggedPlayer.uid
      )) ||
      state.tokenResult?.claims.license === "sysadmin")
  ) {
    return (
      <Draggable
        key={mid + "/" + position + "/" + postitname}
        id={mid + "/" + position + "/" + postitname}
        data={{
          // player: player,
          // event: event,
          // matchesPlayersAndSpots: matchesPlayersAndSpots,
          position: position,
          mid: mid,
          postitname: postitname,
          // lid: "toto",
        }}
        // playerDragged={playerDragged}
      >
        <PlayerPostitPresentation
          {...props}
          postitname={postitname}
          onClick={() => {
            if (onClick) {
              onClick(mid, position, postitname);
            }
          }}
        />
      </Draggable>
    );
  } else {
    return (
      <PlayerPostitPresentation
        {...props}
        postitname={postitname}
        onClick={() => {}}
      />
    );
  }
};

export const OriginalPlayerPostit = (props: PlayerPostItProps) => {
  const { mid, position, postitname } = props;
  const [name, setName] = useState(postitname);
  const { state, setState } = useContext(AppContext);

  if (
    state &&
    state.loggedPlayer &&
    ((state.currentLeague &&
      state.leagues[state.currentLeague].admins.includes(
        state.loggedPlayer.uid
      )) ||
      state.tokenResult?.claims.license === "sysadmin")
  ) {
    return (
      <Draggable
        key={"originalpostit-" + postitname}
        id={"originalpostit-" + postitname}
        data={{
          // player: player,
          // event: event,
          // matchesPlayersAndSpots: matchesPlayersAndSpots,
          position: position,
          mid: mid,
          postitname: name,
          // lid: "toto",
        }}
        // playerDragged={playerDragged}
      >
        <PlayerPostitPresentation
          {...props}
          postitname={name}
          onClick={() => {
            const newname = window.prompt("New name", "");
            if (newname) setName(newname);
          }}
        />
      </Draggable>
    );
  } else {
    return <></>;
  }
};

export const PlayerButton = (
  props: PlayerButtonProps & { playerDragged?: PlayerButtonProps } & {
    forAvailability: boolean;
  } & { currentPlayer: string | undefined } & {
    setCurrentPlayer: (np: string | undefined) => void;
  }
) => {
  const { state, setState } = useContext(AppContext);
  const {
    player,
    // matchesPlayersAndSpots,
    playerDragged,
    forAvailability,
    position,
    mid,
    currentPlayer,
    // lid,
  } = props;

  // RENDER

  if (state && player) {
    if (
      state &&
      state.loggedPlayer &&
      ((state.currentLeague &&
        state.leagues[state.currentLeague].admins.includes(
          state.loggedPlayer.uid
        )) ||
        state.tokenResult?.claims.license === "sysadmin")
    ) {
      return (
        <Draggable
          key={mid + "/" + position + "/" + player.PlayerID}
          id={mid + "/" + position + "/" + player.PlayerID}
          data={{
            player: player,
            // event: event,
            // matchesPlayersAndSpots: matchesPlayersAndSpots,
            position: position,
            mid: mid,
            // lid: lid,
          }}
          // playerDragged={playerDragged}
        >
          <PlayerButtonPresentation
            key={"DPBP-" + player.PlayerID}
            {...props}
          />
        </Draggable>
      );
    } else {
      return (
        <PlayerButtonPresentation key={"PBP-" + player.PlayerID} {...props} />
      );
    }
  } else return <></>;
};
