import "../App.css";

// REACT + GENERAL LIBRARIES

import * as _ from "lodash";
import dayjs, { Dayjs } from "dayjs";

// CONTEXTS

import { useContext } from "react";

// MUI

import { Box, Stack, Button, Divider } from "@mui/material";

// MUI ICONS

import AddIcon from "@mui/icons-material/Add";

// TYPES

import { EventType } from "../types";

import { AppContext } from "../App";

import { TennisCourt } from "./TennisCourt";

import { PlayerButtonProps } from "./PlayerButton";

// FUNCTIONS

import { DraftStateType } from "./EventDraftBar";
import { hPlayersWithAvailabilitiesType } from "./Event";
import {
  PlayerDetailsTypeWithAvailability,
  hCourtTimeType,
  PlayerDetailsTypeWithAvailabilityAndIndex,
} from "../types";

type EventMatchesProps = {
  event: EventType;
  draftState: DraftStateType;
  setDraftState: (ns: DraftStateType) => void;
  showDetails: boolean;
  players: hPlayersWithAvailabilitiesType;
  playerDragged: PlayerButtonProps | null;
  currentPlayer: string | undefined;
  setCurrentPlayer: (np: string | undefined) => void;
  lid: string;
  addCourt: () => void;
  editor: boolean;
  changePostIt: (mid?: string, position?: string, postitname?: string) => void;
  courtTimes: hCourtTimeType;
};

export const EventMatches = ({
  event,
  draftState,
  setDraftState,
  showDetails,
  players,
  playerDragged,
  currentPlayer,
  setCurrentPlayer,
  lid,
  addCourt,
  editor,
  changePostIt,
  courtTimes,
}: EventMatchesProps) => {
  const { state, setState } = useContext(AppContext);

  const league = state.leagues[lid];

  var sortedPlayers: PlayerDetailsTypeWithAvailabilityAndIndex[] =
    Object.values(players)
      .sort(
        (
          a: PlayerDetailsTypeWithAvailability,
          b: PlayerDetailsTypeWithAvailability
        ) => {
          if (event.priorityPolicy === "leastCourtTime") {
            return (courtTimes[a.PlayerID || ""]?.minutes || 0) >
              (courtTimes[b.PlayerID || ""]?.minutes || 0)
              ? 1
              : -1;
          } else if (event.priorityPolicy === "leastCourtTimeAgainstPlan") {
            if (
              !league.playerDetails[a.PlayerID || ""] ||
              league.playerDetails[a.PlayerID || ""].contractshares === null ||
              league.playerDetails[a.PlayerID || ""].contractshares === 0
            )
              return 1;
            if (
              !league.playerDetails[b.PlayerID || ""] ||
              league.playerDetails[b.PlayerID || ""].contractshares === null ||
              league.playerDetails[b.PlayerID || ""].contractshares === 0
            )
              return -1;

            return (courtTimes[a.PlayerID || ""]?.minutes || 0) /
              (league.playerDetails[a.PlayerID || ""]?.contractshares || 1) >
              (courtTimes[b.PlayerID || ""]?.minutes || 0) /
                (league.playerDetails[b.PlayerID || ""]?.contractshares || 1)
              ? 1
              : -1;
          } else if (event.priorityPolicy === "firstAvailable") {
            return (event.availabilities[a.PlayerID || ""]
              ?.dateFirstAvailable || dayjs().toISOString()) >
              (event.availabilities[b.PlayerID || ""]?.dateFirstAvailable ||
                dayjs().toISOString())
              ? 1
              : -1;
          } else {
            return a.name > b.name ? 1 : -1;
          }
        }
      )
      .map((p, index) => {
        return { ...p, index: index + 1 };
      });
  var hSortedPlayers = _.keyBy(sortedPlayers, "PlayerID");

  return (
    <Box
      sx={{
        overflow: "auto",
        backgroundColor: draftState.editMode && false ? "#eeeecc" : "#f5f5ff",
        padding: 1,
        paddingRight: 10,
        justifyContent: "center",
      }}
    >
      {/* 
                
                Tennis courts
                
                */}

      <Stack
        direction="row"
        sx={{ margin: 0, display: "flex" }}
        divider={
          <Divider orientation="vertical" flexItem sx={{ margin: 0.3 }} />
        }
        spacing={0}
      >
        {Object.values(event.matches)
          .sort((a, b) => (a.order < b.order ? -1 : 1))
          .map((m) => (
            <TennisCourt
              key={m.MatchID}
              event={event}
              match={m}
              league={state.leagues[lid]}
              showDetails={showDetails}
              playersWithAvailabilities={hSortedPlayers}
              playerDragged={playerDragged ? playerDragged : undefined}
              currentPlayer={currentPlayer}
              setCurrentPlayer={setCurrentPlayer}
              showTimes={
                Object.values(event.matches).findIndex(
                  (m) => m.offset && m.offset !== 0
                ) >= 0
              }
              draftState={draftState}
              setDraftState={setDraftState}
              changePostIt={changePostIt}
            />
          ))}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginLeft: 2,
          }}
        >
          {/* you can only create courts if admin of this league */}
          {editor && !draftState.editMode ? (
            <Stack direction="row">
              <Button
                variant="contained"
                onClick={() => {
                  addCourt();
                }}
              >
                <AddIcon />
              </Button>
              <Box sx={{ minWdith: 30 }}>&nbsp;&nbsp;</Box>
            </Stack>
          ) : (
            <></>
          )}
        </Box>
      </Stack>
    </Box>
  );
};
